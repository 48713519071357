
import './packs.css';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompareCardContext } from '../../../components/compare/CompareCard';
import Animate from '../../about/Animate';
import { Carousel } from 'react-bootstrap';
const Ads = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();


            


 
  return (
    <Animate delay={0.1} >
    <div className="packs ">
       
    <Slider />
  
    </div>
    </Animate>
    );  

};

const Slider =(
)=> {
  const [index, setIndex] = React.useState(0);
  const [mobile, setMobile] = React.useState(false);
  const {t, i18n} = useTranslation();
const handleSelect = (selectedIndex: any, e: any) => {
  setIndex(selectedIndex);
};

const navigate = useNavigate();
const {addToCart} = useContext(CompareCardContext);

  React.useEffect(() => { 
      if (window.innerWidth < 992) {
          setMobile(true);
      }
  }, [])

  return (
          <Animate delay={0.1}>
                      
                      <Animate delay={0.3}>
                      <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={null} indicators={false}>
                        <Carousel.Item >
                            <div className='d-flex justify-content-center'>
                                <div className='d-flex flex-column align-items-center'>
                                    <img src="https://storagetygo01.blob.core.windows.net/images/ads/web/Banner1.jpg" alt="1" style={{width:"100%"}}/>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='d-flex justify-content-center'>
                                <div className='d-flex flex-column align-items-center'>
                                    <img src="https://storagetygo01.blob.core.windows.net/images/ads/web/Banner2.jpg" alt="1" style={{width:"100%"}}/>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='d-flex justify-content-center'>
                                <div className='d-flex flex-column align-items-center'>
                                    <img src="https://storagetygo01.blob.core.windows.net/images/ads/web/Banner3.jpg" alt="1" style={{width:"100%"}}/>
                                </div>
                            </div>
                        </Carousel.Item>
                      </Carousel>
                      
                      </Animate>
                      
                      
                      
                      <Animate delay={0.5}>
                      <div className='d-flex my-4 mx-auto justify-content-center '>
                          {Array.from({length: 3}).map((_, index_) => {
                          {/* {Array.from({length: plans.length}).map((_, index_) => { */}
                              return (
                                      <div className='me-3' onClick={() => setIndex(index_)} style={{cursor: "pointer", width: "32px", height: "6px", borderRadius: "3px", backgroundColor: index === index_ ? "#3EB6AE" : "#B3B3B3"}}></div>
                              )
                          })}
                      </div>    
                      </Animate>     
                  
          </Animate>
  )

}

export default Ads;
